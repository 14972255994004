import React  from "react"

import './software-documentation.component.less';

import ArrowDown from "../../assets/img/icon/arrow_down.svg"
import SEO from "../seo";
import {useTranslation} from "react-i18next";
import {useI18next} from "gatsby-plugin-react-i18next";

const SoftwareDocumentationComponent = () => {
    const { i18n, t } = useTranslation()
    const { originalPath } = useI18next()

    function openAccardion() {
        let block = document.getElementById('dfs_acardion_wrapper');
        let arrows = document.getElementById('dfs_arrow_down');
        let text = document.getElementById('dfs_btn_text');
        if (!block.classList.contains('documentation_for_software__acardion_wrapper-close')){
            block.classList.add('documentation_for_software__acardion_wrapper-close');
            arrows.classList.add('documentation_for_software__img_arrows_left_menu-close');
            text.innerHTML = 'Подробнее';
        } else {
            block.classList.remove('documentation_for_software__acardion_wrapper-close');
            arrows.classList.remove('documentation_for_software__img_arrows_left_menu-close');
            text.innerHTML = 'Свернуть';
        }
    }
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div>
            <SEO description={t("metaDescription")} lang={i18n.language} title={t("titleSoftwareDocumentation")} link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="wrapper_container_main wrapper_container_main-flex wrapper_container_main-documentation_for_software">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                            <div className="documentation_for_software__main_name">
                                Документация на&nbsp;программное обеспечение (программы для ЭВМ)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper_container wrapper_container-gray">
                <div
                    className="container documentation_for_software_block_1__container">
                    <div className="row">

                        <div className="documentation_for_software__blocks"> 

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">
                                        Программа для ЭВМ «Библиотека подпрограмм для модуля анализа и расчета рассеяния загрязняющих веществ в атмосфере» 
                                    </div>
                                    <div className="documentation_for_software__description">
                                        Данная библиотека подпрограмм предназначена для работы с дисперсионной моделью рассеивания загрязнения в атмосфере FLEXPART. В нее входит набор инструментов, необходимый для автоматизации запусков расчетов модели.
                                    </div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text documentation_for_software__block_info_footer-text_border-bottom">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/User_manual.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Руководство пользователя</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">
                                        Программа для ЭВМ «Система сбора, хранения, обработки и визуализации данных о качестве атмосферного воздуха CityAir.Basic» 
                                    </div>
                                    <div className="documentation_for_software__description">
                                        Программный комплекс CityAir.Basic представляет собой сервис, предназначенный для сбора, хранения, обработки и визуализации данных о качестве атмосферного воздуха на картографической подоснове в режиме реального времени.
                                    </div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/Exploitation-guide.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация для эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">
                                        Программа для ЭВМ «Система моделирования рассеивания пассивных примесей от источников CityAir.Plume» 
                                    </div>
                                    <div className="documentation_for_software__description">
                                    Система моделирования рассеивания пассивных примесей от источников CityAir.Plume является модулем программного комплекса CityAir.Basic, который предназначен для сбора, хранения, обработки и визуализации данных о качестве атмосферного воздуха на картографической подоснове в режиме реального времени. 
                                    </div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/plume/Exploitation-guide-CityAir.Plume.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация для эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">
                                        Программа для ЭВМ «Система расчета переноса химически активных примесей в атмосферном воздухе CityAir.Forecast» 
                                    </div>
                                    <div className="documentation_for_software__description">
                                        Система расчета переноса химически активных примесей в атмосферном воздухе CityAir.Forecast является модулем программного комплекса CityAir.Basic, который предназначен для прогнозирования концентраций загрязняющих веществ в воздухе на контролируемой территории (города, района) с учетом прогноза метеорологических условий. 
                                    </div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/forecast/Exploitation-guide-CityAir.Forecast.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация для эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SoftwareDocumentationComponent.propTypes = {}

SoftwareDocumentationComponent.defaultProps = {}

export default SoftwareDocumentationComponent
